// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import TextareaAutogrow from "stimulus-textarea-autogrow"
import ScrollTo from "stimulus-scroll-to"
import Flatpickr from "stimulus-flatpickr"
import PasswordVisibility from 'stimulus-password-visibility'
import Clipboard from 'stimulus-clipboard'

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

application.register("textarea-autogrow", TextareaAutogrow)
application.register("scroll-to", ScrollTo)
application.register("flatpickr", Flatpickr)
application.register('password-visibility', PasswordVisibility)
application.register('clipboard', Clipboard)
