import { Controller } from 'stimulus';
import { Select } from "mdb-ui-kit";

export default class extends Controller {
  resetSearch() {
    const selects = document.querySelectorAll('select');
    const ageMin = 18;
    const ageMax = 99;
    const heightMin = 122;
    const heightMax = 213;

    // Reset age sliders
    this.dispatchSliderEvent("resetSlider", { type: 'age', min: ageMin, max: ageMax});

    // Reset height sliders
    this.dispatchSliderEvent("resetSlider", { type: 'height', min: heightMin, max: heightMax });

    // Reset MDB selects
    selects.forEach(select => {
      const selectInstance = Select.getInstance(select) || new Select(select);
      if (selectInstance) {
        select.options.forEach(option => {
          option.removeAttribute('selected');
        });
        selectInstance.setValue('1'); 
      }
    });
  }

  dispatchSliderEvent(eventName, detail) {
    const event = new CustomEvent(eventName, { detail });
    window.dispatchEvent(event);
  }
}
