import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    // Get the hash from the URL (e.g., #accordionFlushExampleXIDeclined)
    const hash = window.location.hash;

    if (hash) {
      // Remove the leading '#' from the hash to get the ID
      const accordionId = hash.substring(1);

      // Find the accordion element by its ID
      const accordionElement = document.getElementById(accordionId);

      if (accordionElement) {
        // Find the accordion button and content within the found accordion element
        const accordionButton = accordionElement.querySelector('[data-mdb-toggle="collapse"]');
        const accordionContent = accordionElement.querySelector(`[data-mdb-parent="#${accordionId}"]`);

        if (accordionButton && accordionContent) {
          accordionButton.classList.remove('collapsed');
          accordionButton.setAttribute('aria-expanded', 'true');

          accordionContent.classList.add('show');
        }
      }
    }
  }
}
