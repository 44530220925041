import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container","chipsButton"];


  //chips conoller displays the first row of chips and toggle buttons for others (to show all chips)
  // <div data-controller="chips">
  //   /* <!-- Chip Container -->
  //   <div data-chips-target="container" class="chips-container">
  //     <% selected_values.reject(&:blank?).each do |selected_value| %>
  //         <span>Chip_name:</span>
  //         <span><%= selected_value %></span
  //       </div>
  //     <% end %>
  //   </div>
  //   <!-- Toggle Button -->
  //   <button data-action="chips#toggle" data-chips-target="button" class="btn btn-primary mt-3 d-none">
  //     Show All Chips
  //   </button>
  // </div>



  connect() {
    this.checkForOverflow();
  }

  toggle(e) { 
    e.preventDefault();
    if (this.containerTarget.classList.contains("expanded")) {
      // Collapse the container by setting it back to its collapsed height
      this.containerTarget.style.maxHeight = `${this.collapsedHeight}px`;
      this.chipsButtonTarget.textContent = "Show All Filters";
    } else {
      // Expand the container by setting it to the full content height
      this.containerTarget.style.maxHeight = `${this.containerTarget.scrollHeight}px`;
      this.chipsButtonTarget.textContent = "Show Less Filters";
    }
    this.containerTarget.classList.toggle("expanded");
  }

  checkForOverflow() {
    this.collapsedHeight = this.containerTarget.clientHeight;
    const isOverflowing = (this.containerTarget.scrollHeight - this.containerTarget.clientHeight) > 22;

    if (isOverflowing) {
      this.chipsButtonTarget.classList.remove("d-none"); // Show the button if content is overflowing
    } else {
      this.chipsButtonTarget.classList.add("d-none"); // Hide the button if no overflow
    }
  }
}
