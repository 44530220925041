import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];
  // <div data-controller="tabs">
  // <!-- Nav tabs -->
  // <ul  role="tablist">
  //   <li>
  //     <a href="#"  data-tabs-target="tab" data-action="click->tabs#change">Tab1</a>
  //   </li>
  //   <li>
  //     <a href="#" data-tabs-target="tab" data-action="click->tabs#change">Tab2</a>
  //   </li>
  // </ul>
  // <!-- Tab panes -->
  // <div >
  //   <div class="show active" data-tabs-target="content">
  //   Content Tab 1
  //   </div>
  //   <div class="" data-tabs-target="content">
  //     Content Tab 2
  //   </div>
  // </div>

  connect() {
    console.log("connect Tabs");
    this.showActiveTab();
  }

  change(event) {
    event.preventDefault();
    this.tabTargets.forEach((tab, index) => {
      if (tab === event.currentTarget) {
        tab.classList.add("active");
        this.contentTargets[index].classList.add("show", "active");
      } else {
        tab.classList.remove("active");
        this.contentTargets[index].classList.remove("show", "active");
      }
    });
  }

  showActiveTab() {
    const activeTab = this.tabTargets.find((tab) =>
      tab.classList.contains("active")
    );
    const activeIndex = this.tabTargets.indexOf(activeTab);
    this.contentTargets[activeIndex].classList.add("show", "active");
  }
}
